<!-- 招标变更模板 -->
<template>
  <div class="mains">
    <div class="templates">
      <p style="text-align: center">
        <span style="font-size: 18px;">{{
          showList.bidding_name
        }}</span>
      </p>
      <p style="text-align: center">
        <strong><span style="font-size: 18px">资格预审公告</span></strong>
      </p>
      <p style="text-align: center">
        <span style="font-size: 18px"
          >（招标编号：<span
            style="font-size: 18px; text-align: center; "
            >{{ showList.serial_number }}</span
          >）</span
        >
      </p>
      <p style="text-align: left">
        <strong><span style="font-size: 16px">一、基本信息</span></strong>
      </p>
      <p style="text-align: left">
        <span style="font-size: 14px"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变更编号：<span
            style="font-size: 14px; "
            >{{ messages.data.change_tender_notice.change_serial_number }}</span
          ></span
        >
      </p>
      <p style="text-align: left">
        <span style="font-size: 14px"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变更名称：<span
            style="font-size: 14px; "
            >{{ messages.data.change_tender_notice.change_name }}</span
          ></span
        >
      </p>
      <p style="text-align: left">
        <strong><span style="font-size: 16px">二、变更说明</span></strong>
      </p>
      <p style="text-align: left">
         <div style="padding-left: 32px;" v-html=" messages.data.change_tender_notice.change_explain"></div>
      </p>
      <p style="text-align: left"><strong style="font-size: 16px">三、变更内容</strong></p>

      <div v-for="(item, index) in messages.data.mark_change_data" :key="index">
        <p style="text-align: left">
          <strong
            ><span style="font-size: 14px">{{ index + 1 }} 、名称</span></strong
          ><span style="font-size: 14px"
            >：<strong>{{ item.location_field }}</strong></span
          >
        </p>
        <p style="text-align: left"></p>
        <p style="text-align: left">
          <span style="font-size: 14px"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变更前：</span
          >
        </p>
        <div class="showDIV">
          <div v-if="item.location_mark=='mark_release'">
            {{item.init_data.release_start_time}} - {{item.init_data.release_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_sign_up'">
            {{item.init_data.sign_up_start_time}} - {{item.init_data.sign_up_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_inquiry'">
            {{item.init_data.inquiry_start_time}} - {{item.init_data.inquiry_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_tender'">
            {{item.init_data.tender_start_time}} - {{item.init_data.tender_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_bidding'">
            {{item.init_data.bidding_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_bidding_period'">
            {{item.init_data.bidding_period_start_time}} - {{item.init_data.bidding_period_end_time}}
          </div>
					<div v-if="item.location_mark=='mark_project_address'">
					  {{item.init_data}}
					</div>
          <div v-if="item.location_mark == 'mark_tender_document'">
            <div>
              <el-table
                :data="item.init_data.notice_bidder_file_type"
                :header-cell-style="headerStyle"
                :row-style="rowStyle"
              >
                <el-table-column label="序号" width="50" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="serial_number"
                  label="投标文件编号"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="file_name"
                  label="投标文件名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="file_format"
                  label="投标文件格式"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="describe" label="描述" align="center">
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div v-if="item.location_mark == 'department'">
            {{ item.init_data }}
          </div>

          <div v-if="item.location_mark == 'project_name'">
            {{ item.init_data }}
          </div>

          <div v-if="item.location_mark == 'project_address_details'">
            {{ item.init_data }}
          </div>

          <div v-if="item.location_mark == 'notice_contact'">
            <div
              class="list"
              v-for="(items, index) in item.init_data"
              :key="index"
              style="margin-top: 0; margin-bottom: 10px"
            >
              <div class="title">
                <span>{{ items.account_name }}</span>
              </div>
              <p>
                <label>姓名</label>
                <span>{{ items.contact_name }}</span>
              </p>
              <p>
                <label>联系方式</label>
                <span>{{ items.contact_way }}</span>
              </p>
              <p>
                <label>地址</label>
                <span>{{ items.address }}</span>
              </p>
              <p>
                <label>邮箱</label>
                <span>{{ items.email }}</span>
              </p>
            </div>
          </div>

          <div v-if="item.location_mark == 'notice_tender_file'">
            <el-table
              :data="item.init_data"
              :header-cell-style="headerStyle"
              :row-style="rowStyle"
            >
              <el-table-column label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="file_name" label="文件名称" align="center">
              </el-table-column>
              <el-table-column prop="file_format" label="附件" align="center">
                <template slot-scope="scope">
                  <div class="possitions">
                    <div>
                      <img
                        :src="
                          require(`@/assets/img/files/` +
                            choseFilesIcon(scope.row.file_full_name) +
                            '.png')
                        "
                        alt=""
                      />
                    </div> 
                    <div>
                      <p>{{ scope.row.file_name }}</p>
                      <p style="color: #999">{{ scope.row.file_size }}</p>
                    </div>
                    <span @click="downFiles(scope.row)">
                      <i class="el-icon-download"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-if="item.location_mark == 'notice_quotation_list'">
            <div v-for="(items, indexs) in item.other_data" :key="indexs">
              <p>{{ items.block_name }}</p>
              <el-table
                :data="items.init_data"
                :header-cell-style="headerStyle"
                :row-style="rowStyle"
              >
                <el-table-column label="序号" width="50" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="logistics_number" label="编号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="specifications" label="描述" align="center">
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column prop="quantity" label="数量" align="center">
                </el-table-column>
                <el-table-column
                  prop="date_of_delivery"
                  label="交货期(天)"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <p style="text-align: left">
          <span style="font-size: 14px"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变更后：</span
          >
        </p>
        <div class="showDIV">
          <div v-if="item.location_mark=='mark_release'">
            {{item.change_after_data.release_start_time}} - {{item.change_after_data.release_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_sign_up'">
            {{item.change_after_data.sign_up_start_time}} - {{item.change_after_data.sign_up_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_inquiry'">
            {{item.change_after_data.inquiry_start_time}} - {{item.change_after_data.inquiry_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_tender'">
            {{item.change_after_data.tender_start_time}} - {{item.change_after_data.tender_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_bidding'">
            {{item.change_after_data.bidding_end_time}}
          </div>
          <div v-if="item.location_mark=='mark_bidding_period'">
            {{item. change_after_data.bidding_period_start_time}} - {{item. change_after_data.bidding_period_end_time}}
          </div>
					<div v-if="item.location_mark=='mark_project_address'">
					  {{item.change_after_data}}
					</div>
          <div v-if="item.location_mark == 'mark_tender_document'">
            <el-table
              :data="item.change_after_data.notice_bidder_file_type"
              :header-cell-style="headerStyle"
              :row-style="rowStyle"
            >
              <el-table-column label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="serial_number"
                label="投标文件编号"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="file_name"
                label="投标文件名称"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="file_format"
                label="投标文件格式"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="describe" label="描述" align="center">
              </el-table-column>
            </el-table>
          </div>

          <div v-if="item.location_mark == 'department'">
            {{ item.change_after_data }}
          </div>

          <div v-if="item.location_mark == 'project_name'">
            {{ item.change_after_data }}
          </div>

          <div v-if="item.location_mark == 'project_address_details'">
            {{ item.change_after_data }}
          </div>

          <div v-if="item.location_mark == 'notice_release_process'">
            <div class="contents">
              <div>公告发布</div>
              <div>{{ item.change_after_data.release_start_time }}</div>
              <div>-</div>
              <div>{{ item.change_after_data.release_end_time }}</div>
            </div>
            <div class="contents">
              <div>报名阶段</div>
              <div>{{ item.change_after_data.sign_up_start_time }}</div>
              <div>-</div>
              <div>{{ item.change_after_data.sign_up_end_time }}</div>
            </div>
            <div class="contents">
              <div>资格预审</div>
              <div>{{ item.change_after_data.inquiry_start_time }}</div>
              <div>-</div>
              <div>{{ item.change_after_data.inquiry_end_time }}</div>
            </div>
            <div class="contents">
              <div>标书购买</div>
              <div>{{ item.change_after_data.tender_buy_start_time }}</div>
              <div>-</div>
              <div>{{ item.change_after_data.tender_buy_end_time }}</div>
            </div>
            <div class="contents">
              <div>投标阶段</div>
              <div>{{ item.change_after_data.bidding_period_start_time }}</div>
              <div>-</div>
              <div>{{ item.change_after_data.bidding_period_end_time }}</div>
            </div>
            <div class="contents">
              <div>开标时间</div>
              <div>{{ item.change_after_data.bidding_end_time }}</div>
              <div></div>
              <div></div>
            </div>
          </div>

          <div v-if="item.location_mark == 'notice_contact'">
            <div
              class="list"
              v-for="(items, index) in item.change_after_data"
              :key="index"
              style="margin-top: 0; margin-bottom: 10px"
            >
              <div class="title">
                <span>{{ items.account_name }}</span>
              </div>
              <p>
                <label>姓名</label>
                <span>{{ items.contact_name }}</span>
              </p>
              <p>
                <label>联系方式</label>
                <span>{{ items.contact_way }}</span>
              </p>
              <p>
                <label>地址</label>
                <span>{{ items.address }}</span>
              </p>
              <p>
                <label>邮箱</label>
                <span>{{ items.email }}</span>
              </p>
            </div>
          </div>

          <div v-if="item.location_mark == 'notice_tender_file'">
            <el-table
              :data="item.change_after_data"
              :header-cell-style="headerStyle"
              :row-style="rowStyle"
            >
              <el-table-column label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="file_name" label="文件名称" align="center">
              </el-table-column>
              <el-table-column prop="file_format" label="附件" align="center">
                <template slot-scope="scope">
                  <div class="possitions">
                    <div>
                      <img
                        :src="
                          require(`@/assets/img/files/` +
                            choseFilesIcon(scope.row.file_full_name) +
                            '.png')
                        "
                        alt=""
                      />
                    </div>
                    <div>
                      <p>{{ scope.row.file_name }}</p>
                      <p style="color: #999">{{ scope.row.file_size }}</p>
                    </div>
                    <span @click="downFiles(scope.row.attachment_url)">
                      <i class="el-icon-download"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div v-if="item.location_mark == 'notice_quotation_list'">
            <div v-for="(items, indexs) in item.other_data" :key="indexs">
              <p>{{ items.block_name}}</p>
              <el-table
                :data="items.change_after_data"
                :header-cell-style="headerStyle"
                :row-style="rowStyle"
              >
                <el-table-column label="序号" width="50" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="logistics_number" label="编号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="specifications" label="描述" align="center">
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column prop="quantity" label="数量" align="center">
                </el-table-column>
                <el-table-column
                  prop="date_of_delivery"
                  label="交货期(天)"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>


      </div>
      <p style="text-align: right">
        <span style="font-size: 14px; "><br /></span
        ><span style="font-size: 16px"
          >招标机构：<span style="font-size: 16px; ">{{
            showList.institutions
          }}</span
          >&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p style="text-align: right">
        <span><span style="font-size: 16px"
            >{{
              messages.data.change_tender_notice.change_release_at
            }}&nbsp;</span
          ><span style="font-size: 14px"> </span></span
        ><span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span> &nbsp;
        &nbsp;<br />
      </p>
    </div>
    <div class="rights">
      <p  @click="lookMore">{{ showList.institutions }}</p>
      <p>发布时间：{{ showList.release_start_time }}</p>
      <!-- <div>加入商机</div> -->
      <!-- <el-divider></el-divider> -->
      <!-- <div class="timesAll" :class="{invalid:invalid}">
        <img
          style="width: 20px"
          src="@/assets/img/collection/shareOperation.png"
          alt=""
        />
        <span>时间汇总</span>
      </div> -->
      <div style='margin-top:28px'>
        <el-timeline :reverse="false">
          <el-timeline-item
            v-for="(item, index) in activities"
            :key="index"
            color="#0088fe"
            :timestamp="item.titles"
            placement="top"
          >
            <p class="times">{{ item.start }}</p>
            <p class="times">{{ item.end }}</p>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>
<script>
import { choseFilesIcon } from "@/components/phmAll/choesImgs.js";
export default {
  props: ["messages"],
  data() {
    return {
      choseFilesIcon,
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "48px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "48px",
      },
      showColor: true,
      allData: {},
      showList: {
        bidding_name: "&{招标名称}",
        serial_number: "&{招标编号}",
        project_describe: "&{项目描述}",
        username: "&{招标人}",
        notice_block_set: "&{标段设置}",
        preliminary_data_other: "&{投标人资格要求}",
        release_start_time: "&{公告发布时间}",
        release_end_time: "&{公告发布时间}",
        institutions: "${招标机构}",
        bidding_end_time: "${开标时间}",
        project_address_details: "${招标人地址}",
        bidding_period_end_time: "${投标截止时间}",
        tender_buy_start_time: "${标书购买时间}",
        tender_buy_end_time: "${标书购买截止}",
        domain: "${域名}",
      },
      invalid:false,//是否可以加入商机
      notice_contact: [
        {
          contact_name: "",
        },
        {
          contact_name: "",
        },
      ],
      notice_block_set: [],
      activities: [],
    };
  },
  methods: {
    downFiles(val){
      this.$axios.getDownload(
            "/base/file/down",
            { attachment_id: val.attachment_id, model_name: "" },
            val.full_name || val.name || val.file_name
          );
    },
    lookMore() {
      let company_id = this.allData.company_id
      this.$router.push({
        path: "/messageList",
        query: {
          company_id: company_id,
          type:this.$route.query.type,
        },
      });
    },
  },
  created() {
    let dats = JSON.parse(JSON.stringify(this.messages));
    this.allData = dats.data.notice;
    this.allData.domain = dats.data.domain;
    for (let keys in this.showList) {
      this.showList[keys] = this.allData[keys];
    }
    this.notice_block_set = this.allData["notice_block_set"];
    this.notice_contact = this.allData["notice_contact"] || []; //联系人方式
    let activities = [];
    if (this.allData.preliminary_data_type == 0) {
      // 表示关闭资格预审
      activities[0] = {
        titles: "公告时间",
        start: "开始：" + this.allData.release_start_time,
        end: "结束：" + this.allData.release_end_time,
      };
      activities[1] = {
        titles: "报名时间",
        start: "开始：" + this.allData.sign_up_start_time,
        end: "结束：" + this.allData.sign_up_end_time,
      };
      activities[2] = {
        titles: "标书购买",
        start: "开始：" + this.allData.tender_buy_start_time,
        end: "结束：" + this.allData.tender_buy_end_time,
      };
      activities[3] = {
        titles: "投标时间",
        start: "开始：" + this.allData.bidding_period_start_time,
        end: "结束：" + this.allData.bidding_period_end_time,
      };
      activities[4] = {
        titles: "开标时间",
        start: "开始：" + this.allData.bidding_end_time,
        end: "",
      };
    } else {
      // 表示开启资格预审
      activities[0] = {
        titles: "公告时间",
        start: "开始：" + this.allData.release_start_time,
        end: "结束：" + this.allData.release_end_time,
      };
      activities[1] = {
        titles: "报名时间",
        start: "开始：" + this.allData.sign_up_start_time,
        end: "结束：" + this.allData.sign_up_end_time,
      };
      activities[2] = {
        titles: "资格预审",
        start: "开始：" + this.allData.inquiry_start_time,
        end: "结束：" + this.allData.inquiry_end_time,
      };
      activities[3] = {
        titles: "标书购买",
        start: "开始：" + this.allData.tender_buy_start_time,
        end: "结束：" + this.allData.tender_buy_end_time,
      };
      activities[4] = {
        titles: "投标时间",
        start: "开始：" + this.allData.bidding_period_start_time,
        end: "结束：" + this.allData.bidding_period_end_time,
      };
      activities[5] = {
        titles: "开标时间",
        start: "开始：" + this.allData.bidding_end_time,
        end: "",
      };
    }
    this.activities = activities;
    this.showColor = false; // 祛除颜色
  },
};
</script>

<style lang="scss" scoped>
.templates {
  padding: 40px;
  line-height: 32px;
  box-sizing: border-box;
}
.mains {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  & > div:nth-child(1) {
    width: 880px;
    background-color: #fff;
  }
  & > div:nth-child(2) {
    width: 300px;
    margin-left: 20px;
    background-color: #fff;
    height: auto;
    box-sizing: border-box;
  }
}
.rights {
  padding: 20px;
  box-sizing: border-box;
  & > p:nth-child(1) {
    color: #333;
    font-size: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin: 8px 0 17px 0;
    // line-height: 16px;
    cursor: pointer;
    &:hover{
      color: #0088fe;
      font-weight: bolder;
    }
  }
  & > p:nth-child(2) {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 17px;
  }
  & > div:nth-child(3) {
    // width: 100%;
    // height: 40px;
    // color: #fff;
    // text-align: center;
    // background-color: #f74848;
    // border-radius: 3px;
    // font-size: 16px;
    // line-height: 40px;
  }
}
.timesAll {
  margin-bottom: 18px;
  display: flex;
  img{
    margin-right: 10px;
  }
}
.times {
  font-size: 12px;
  line-height: 28px;
  color: #999;
}
/deep/ .el-timeline-item__timestamp,
.is-top {
  font-weight: 800;
  font-size: 14px;
  color: #0088fe;
}

.showDIV {
  margin-left: 45px;
}

.contents {
  background-color: #fff;
  line-height: 30px;
  height: 30px;
  display: flex;
  & > div:nth-child(1) {
    padding: 0 10px;
  }
  & > div:nth-child(2) {
    padding: 0 10px;
  }
  & > div:nth-child(3) {
    padding: 0 5px;
  }
  & > div:nth-child(4) {
    padding: 0 10px;
  }
}

.list {
  width: 75%;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 20px 15px;
  position: relative;
  background-color: #fafafa;
  margin-top: 10px;
  > .title {
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    > span {
      color: #4387f8;
    }
    .ivu-icon-md-close-circle {
      color: #999999;
      font-size: 16px;
      cursor: pointer;
    }
    .ivu-icon-md-close-circle:hover {
      color: #4387f8;
    }
  }
  > p {
    color: #666666;
    display: flex;
    align-items: center;
    > label {
      width: 144px;
    }
  }
}

.possitions {
  margin-bottom: 5px;
  background-color: #fafafa;
  width: 280px;
  height: 56px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  & > div:nth-child(1) {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  & > div:nth-child(2) {
    flex: 1;
    p {
      text-align: left;
      font-size: 12px;
      line-height: 18px;
    }
  }
  & > span:nth-child(3) {
    position: absolute;
    right: 10px;
    top: 18px;
    cursor: pointer;
    &:hover {
      font-size: 16px;
    }
  }
}
</style>
