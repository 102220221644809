export function choseFilesIcon(val) {
    let getVal = val
    let msg = ''
    if (getVal.lastIndexOf('.') > -1) {
        msg = getVal.slice(getVal.lastIndexOf('.') + 1)
    } else {
        msg = getVal
    };
    console.log(msg);
    switch (msg) {
        case 'docx':
            return 'doc'
            break;
        case 'doc':
            return 'doc'
            break;
        case 'png':
            return 'img'
            break;
        case 'jpg':
            return 'img'
            break;
        case 'gif':
            return 'img'
            break;
        case 'mp3':
            return 'mp3'
            break;
        case 'pdf':
            return 'pdf'
            break;
        case 'ppt':
            return 'ppt'
            break;
		case 'pptx':
		    return 'ppt'
		    break;
        case 'video':
            return 'video'
            break;
        case 'xls':
            return 'xlsx'
            break;
        case 'xlsx':
            return 'xlsx'
            break;
        default:
            return 'system_file'
    }
}