<!-- 中标结果模板 -->
<template>
  <div class="mains">
    <div class="templates">
      <p style="text-align: center">
        <span
          style="font-size: 18px; font-weight: 900"
          :class="showColor ? 'colors' : ''"
          >{{ showList.bidding_name }}</span
        >
      </p>
      <p style="text-align: center">
        <span style="font-size: 18px; font-weight: 900"> 中标结果公告 </span>
      </p>
      <p style="text-align: center">
        <span style="font-size: 18px"
          >（招标编号：<span
            style="font-size: 18px; text-align: center"
            :class="showColor ? 'colors' : ''"
            >{{ showList.serial_number }}</span
          >）</span
        >
      </p>
      <p>
        <span>各投标人：</span>
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          :class="showColor ? 'colors' : ''"
          >{{ showList.bidding_name }}</span
        >(<span :class="showColor ? 'colors' : ''">{{
          showList.serial_number
        }}</span
        ><span>)已按规定程序完成评标工作，现将中标结果公告如下： </span>
      </p>

      <el-table
        v-if="showList.notice_scaling_summary"
        style="margin-top: 20px; width: 100%; margin-bottom: 20px"
        :data="showList.notice_scaling_summary"
        :header-cell-style="headerStyle"
        :row-style="rowStyle"
        border
      >
        <el-table-column label="序号" width="69" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="投标人名称" width="206" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.bind.bind_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="标段名称" width="206" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.notice_block_set.block_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="评标得分" width="206" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.synthesize_score }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="中标结果" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.is_scaling == 1 ? "中标" : "未中标" }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="file-box" v-if="is_file">
        <div>附件：</div>
        <div class="file-item">
          <img :src=" require(`@/assets/img/files/` + choseFilesIcon(attachment.file_full_name) + '.png')"  class="icon-file">
          <div class="left">
            <div class="file_name" :title="attachment.file_full_name">{{attachment.file_full_name}}</div>
            <div class="file-size">{{attachment.file_size}}</div>
          </div>
          <div class="right">
            <p @click="viewFiles(attachment)"><i title="预览" class="el-icon-view cursor"></i> </p>
            <p @click="downFiles(attachment)"><i title="下载" class="el-icon-download cursor"></i></p>
          </div>
        </div>
      </div>
      <p style="text-align: right">
        <span style="font-weight: 900"
          >招标机构：<span >{{
            showList.institutions
          }}</span></span
        >
      </p>
      <p style="text-align: right; font-weight: 900">
        <span >{{
          showList.bidding_end_time
        }}</span>
      </p>
    </div>
    <div class="rights">
      <p  @click="lookMore">{{
            showList.institutions
          }}</p>
      <p>发布时间：{{ showList.release_start_time }}</p>
      <!-- <el-divider></el-divider> -->
      <!-- <div class="timesAll">
        <img
          style="width: 20px"
          src="@/assets/img/collection/shareOperation.png"
          alt=""
        />
        <span>时间汇总</span>
      </div> -->
      <div style='margin-top:28px'>
        <el-timeline :reverse="false">
          <el-timeline-item
            v-for="(item, index) in activities"
            :key="index"
            color="#0088fe"
            :timestamp="item.titles"
            placement="top"
          >
            <p class="times">{{ item.start }}</p>
            <p class="times">{{ item.end }}</p>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/server/ajax.js";
export default {
  props:['messages'],
  data() {
    return {
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "48px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "48px",
      },
      showColor: true,
      allData: {},
      showList: {},
      notice_contact: [
        {
          contact_name: "",
        },
        {
          contact_name: "",
        },
      ],
      attachment:{},
      is_file:false,
      notice_block_set: [],
      activities: [],
    };
  },
  created() {
      let datas =JSON.parse(JSON.stringify(this.messages))
      this.allData = datas.data;
      this.showList = this.allData;
      let notice_award = this.allData.notice_award;
      if(notice_award && notice_award.publicity_attachment){
        this.is_file = true;
        let attachment = notice_award.publicity_attachment;
        this.attachment = notice_award.publicity_attachment;
        this.attachment.file_full_name = attachment.file_full_name||attachment.file_name;
      }
      // console.log('all DATA',this.allData.notice_award);
      let activities = [];
      activities[0] = {
        titles: "公告时间",
        start: "开始：" + this.allData.release_start_time,
        end: "结束：" + this.allData.release_end_time,
      };
      activities[1] = {
        titles: "报名时间",
        start: "开始：" + this.allData.sign_up_start_time,
        end: "结束：" + this.allData.sign_up_end_time,
      };
      if (this.allData.preliminary_data_type == 1) {
        activities[2] = {
          titles: "资格预审",
          start: "开始：" + this.allData.inquiry_start_time,
          end: "结束：" + this.allData.inquiry_end_time,
        };
        activities[3] = {
          titles: "标书购买",
          start: "开始：" + this.allData.tender_buy_start_time,
          end: "结束：" + this.allData.tender_buy_end_time,
        };
        activities[4] = {
          titles: "投标时间",
          start: "开始：" + this.allData.bidding_period_start_time,
          end: "结束：" + this.allData.bidding_period_end_time,
        };
        activities[5] = {
          titles: "开标时间",
          start: "开始：" + this.allData.bidding_end_time,
          end: "",
        };
      } else {
        activities[2] = {
          titles: "标书购买",
          start: "开始：" + this.allData.tender_buy_start_time,
          end: "结束：" + this.allData.tender_buy_end_time,
        };
        activities[3] = {
          titles: "投标时间",
          start: "开始：" + this.allData.bidding_period_start_time,
          end: "结束：" + this.allData.bidding_period_end_time,
        };
        activities[4] = {
          titles: "开标时间",
          start: "开始：" + this.allData.bidding_end_time,
          end: "",
        };
      }
      this.activities = activities;
      this.showColor = false; // 祛除颜色
  },
  methods:{
    viewFiles(val){
      if(!val.image_url){ return 1;}
      console.log('val.image_url',val.image_url);
      let last = val.image_url.lastIndexOf('.'), name =  val.image_url;
      let types =val.image_url.substr(last+1, name.length-1);
      let fileType = ["doc",'docx', "ppt", "xlsx"];
      if (fileType.includes(types)) {
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" + val.image_url
        );
      } else {
        window.open(val.image_url,'_blank');
      }
    },
    lookMore() {
      console.log(this.messages);
      let company_id=this.messages.data.notice_award.company_id
      this.$router.push({
        path: "/messageList",
        query: {
          company_id: company_id,
          type:this.$route.query.type,
        },
      });
    },
    downFile(item){
      item = item || {};
      let image_url = item.image_url || item.attachment_url;
      if (image_url) {
      	window.open(image_url, '_target')
      } else {
      	console.log("文件不存在")
      }
    },
    choseFilesIcon(val) {
        let getVal = val
        let msg = ''
        if(!val){
          return 'doc'
        }
        if (getVal.lastIndexOf('.') > -1) {
            msg = getVal.slice(getVal.lastIndexOf('.') + 1)
        } else {
            msg = getVal
        };
        switch (msg) {
            case 'docx':
                return 'doc'
                break;
            case 'doc':
                return 'doc'
                break;
            case 'png':
                return 'img'
                break;
            case 'jpg':
                return 'img'
                break;
            case 'gif':
                return 'img'
                break;
            case 'mp3':
                return 'mp3'
                break;
            case 'pdf':
                return 'pdf'
                break;
            case 'ppt':
                return 'ppt'
                break;
    		case 'pptx':
    		    return 'ppt'
    		    break;
            case 'video':
                return 'video'
                break;
            case 'xls':
                return 'xlsx'
                break;
            case 'xlsx':
                return 'xlsx'
                break;
            default:
                return 'system_file'
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.templates {
  padding: 40px;
  line-height: 32px;
  box-sizing: border-box;
}
.file-box{
  display: flex;
  align-items: center;
  .file-item{
    display: flex;
    background-color: #FAFAFA;
    padding:6px 12px;
    line-height: 24px;
    align-items: center;
    .icon-file{
      width:32px;
      height:32px;
      margin-right:4px;
    }
    .file-size{
      font-size: 12px;
      color:#666666;
    }
    .cursor{
      cursor: pointer;
    }
    .left{
      .file_name{
        width:180px;
        margin-right: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.mains {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  & > div:nth-child(1) {
    width: 880px;
    background-color: #fff;
  }
  & > div:nth-child(2) {
    width: 300px;
    margin-left: 20px;
    background-color: #fff;
    height: auto;
    box-sizing: border-box;
  }
}
.colors {
  color: rgb(0, 176, 240);
}
.rights {
  padding: 20px;
  box-sizing: border-box;
  & > p:nth-child(1) {
    color: #333;
    font-size: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    margin: 8px 0 17px 0;
    // line-height: 16px;
    cursor: pointer;
    &:hover{
      color: #0088fe;
      font-weight: bolder;
    }
  }
  & > p:nth-child(2) {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 17px;
  }
}
.timesAll {
  margin-bottom: 18px;
  display: flex;
  img{
    margin-right: 10px;
  }
}
.times {
  font-size: 12px;
  line-height: 28px;
 color: #999;
}
/deep/ .el-timeline-item__timestamp,
.is-top {
  font-weight: 800;
  font-size: 14px;
  color: #0088fe;
}
</style>
