<template>
  <div class="navList">
    <header class="business-header">
      <img src="@/assets/img/login/7899.png" class="buss-info" alt="" />
      <span class="spndes" @click="choseOther(7)" :class="{curr:type==7}">询价公告</span>
      <span class="spnsd">|</span>
      <span class="spndes" @click="choseOther(2)" :class="{curr:type==2}">资格预审公告</span>
      <span class="spnsd">|</span>
      <span class="spndes" @click="choseOther(5)" :class="{curr:type==5}">变更公告</span>
      <span class="spnsd">|</span>
      <span class="spndes" @click="choseOther(6)" :class="{curr:type==6}">中标公告</span>
      <span class="spnsd">|</span>
      <span class="spndes" @click="choseOther(1)" :class="{curr:type==1}">招标公告</span>
      <span class="icon-back" @click="goBack">
        返回 <i class="el-icon el-icon-d-arrow-right"></i>
      </span>
    </header>
  </div>
</template>

<script>
export default {
  name: "navList",
	data(){
		return {
			type:0,
		}
	},
  methods: {
    choseOther(val) {
      this.$router.push({
        path:'/messageList',
        query:{
          type:val,
          company_id: this.$route.query.company_id || 0
        }
      })
    },
		goBack(){
      this.$router.back();
			// this.$router.push({ path: "/messageList", query: { type: this.$route.query.type } });
		},
    gotoOther() {
      this.$router.push("/BusinessHome");
    },
  },
	created() {
		let type = this.$route.query.type;
		if(type){
			this.type = type;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../../assets/css/bussinessHeader.scss';
.navList {
  background-color: #fff;
}

</style>
