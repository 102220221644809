<!-- 招标公告企业版 -->
<template>
  <div>
		<div class="mains">
			<navList />
		</div>
    <component
      v-if="isShow"
      :is="templates"
      :messages="messages"
      :id="id"
    ></component>
		<footers v-show="reqEnd" class="footer-margin" />
  </div>
</template>

<script>
import navList from "./navList.vue";
import { get } from "@/server/ajax.js";
import enterprise from "./enterprise.vue";
// import government from "./government.vue";
import examinationEnterprise from "./examinationEnterprise.vue";
// import examinationGovernment from "./examinationGovernment.vue";
import changeTemplate from "./changeTemplate.vue";
import resultWinning from "./resultWinning.vue";
import xunjiaGonggao from './xunjiaGonggao.vue';
import footers from '@/components/footer/footer_tpl.vue';
export default {
  components: {
    navList,
    enterprise,
    // government,
    examinationEnterprise,
    // examinationGovernment,
    changeTemplate,
    resultWinning,
    xunjiaGonggao,
		footers,
  },
  data() {
    return {
      isShow: false,
      reqEnd: false,
      templates: "",
      id: "", //134 439
      messages: {},
      types:'',
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        let router = newVal.query.type;
        this.id = newVal.query.id;
        this.query(router);
      },
    },
  },
  created() {
    //  获取当前登录用户信息 /users/info
    let router = this.$route.query.type;
    this.id = this.$route.query.id;
    this.types = this.$route.query.types || '';
    this.query(router);
  },
  methods: {
    query(val) {
      if (val == 1) {
        get("/bidding/common/template/notice_template/" + this.id).then(
          (res) => {
            if (res.data.notice.preliminary_data_type == 0) {
              // template_type 1-政府,2-企业,3-设置模板,4-自定义
							this.templates = "enterprise";
            } else {
							this.templates = "examinationEnterprise";
            }
            this.messages = res;
            this.isShow = true;
          }
        ).finally(()=>this.reqEnd = true);
      } else if (val == 5) {
        get("/bidding/common/template/change_template/" + this.id).then(
          (res) => {
            this.messages = res;
            this.templates = "changeTemplate";
            this.isShow = true;
          }
        ).finally(() => this.reqEnd = true);
      } else if (val == 6) {
        get("/bidding/common/template/in_tender_template/" + this.id).then(
          (res) => {
            this.messages = res;
            this.templates = "resultWinning";
            this.isShow = true;
          }
        ).finally(() => this.reqEnd = true);
      } else if (val == 7) {
        get('/jc/application/getMiningListsDetail/'+ this.id, {
          type: this.types,
        }).then((res) => {
            this.messages = res;
            this.templates = "xunjiaGonggao";
            this.isShow = true;
        }).finally(() => this.reqEnd = true);
      }
			console.log('templates',this.templates);
    },
  },
};
</script>

<style lang="scss" scoped>
	.mains{
		width:1200px;
		margin:20px auto 0;
	}
	.footer-margin{
		margin-top:20px;
	}
</style>
