<template>
  <el-dialog
    title="选择付款方式"
    :visible.sync="showPayStep"
    width="800px"
    :append-to-body="true"
  >
    <template v-if="payType == 'new'">
      <div class="payMethodCard" :key="key">
        <!-- stage 阶段付 -->
        <template v-if="is_diy.type === 'stage'">
          <vxe-table
            ref="xTable"
            stripe
            :row-config="{ useKey: true, isCurrent: true, isHover: true }"
            :data="tableData"
            :max-height="600"
          >
            <vxe-column type="seq" title="序号" width="60"></vxe-column>
            <vxe-column min-width="100">
              <template #header>
                <p>阶段名称</p>
              </template>
              <template #default="{ row }">
                <p class="duohangEllipsisTextTwo" :title="row.name">
                  {{ row.name || "--" }}
                </p>
              </template>
            </vxe-column>
            <vxe-column min-width="100">
              <template #header>
                <p>付款比例(%)</p>
                <p class="total">(合计：{{ tableData.length ? 100 : 0 }}%)</p>
              </template>
              <template #default="{ row }">
                <p>{{ row.rate }}</p>
              </template>
            </vxe-column>
            <vxe-column min-width="200">
              <template #header>
                <p>付款条件</p>
              </template>
              <template #default="{ row }">
                <div v-for="(item, index) in row.groupHtml" :key="index">
                  <div class="group_content">
                    <div v-html="item"></div>
                  </div>
                  <div
                    class="content_huo"
                    v-if="index !== row.groupHtml.length - 1"
                  >
                    或
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="备注" min-width="160">
              <template #default="{ row }">
                <p class="duohangEllipsisTextTwo" :title="row.remark">
                  {{ row.remark || "--" }}
                </p>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
        <!-- roll 滚付 -->
        <template v-if="is_diy.type === 'roll'">
          <vxe-table
            ref="xTable"
            stripe
            :row-config="{ useKey: true, isCurrent: true, isHover: true }"
            :data="tableData"
          >
            <vxe-column type="seq" title="序号" width="60"></vxe-column>
            <vxe-column width="100">
              <template #header>
                <p>结算周期</p>
              </template>
              <template #default="{ row }">
                <p>{{ cycleShow(row.cycle) }}</p>
              </template>
            </vxe-column>
            <vxe-column min-width="100">
              <template #header>
                <p>结算比例(%)</p>
              </template>
              <template #default="{ row }">
                <p>{{ row.rate }}</p>
              </template>
            </vxe-column>
            <vxe-column min-width="200">
              <template #header>
                <p>结算条件</p>
              </template>
              <template #default="{ row }">
                <div v-for="(item, index) in row.groupHtml" :key="index">
                  <div class="group_content">
                    <div v-html="item"></div>
                  </div>
                  <div
                    class="content_huo"
                    v-if="index !== row.groupHtml.length - 1"
                  >
                    或
                  </div>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="结算日期" min-width="160" show-overflow>
              <template #default="{ row }">
                <p>结算日后{{ row.settlement_day || "--" }}天</p>
              </template>
            </vxe-column>
          </vxe-table>
        </template>
      </div>
    </template>
    <template v-else>
      <el-row>
        <el-col style="margin-top: 10px" :span="2">方式名称</el-col>
        <el-col style="margin-top: 10px" :span="21" :offset="1">{{
          payData.pay_name || "--"
        }}</el-col>
        <el-col style="margin-top: 10px" :span="2">方式编号</el-col>
        <el-col style="margin-top: 10px" :span="21" :offset="1">{{
          payData.pay_code || "--"
        }}</el-col>
        <el-col style="margin-top: 10px" :span="2">方式描述</el-col>
        <el-col style="margin-top: 10px" :span="21" :offset="1">{{
          payData.pay_desc || "--"
        }}</el-col>
      </el-row>
    </template>

    <el-row slot="footer">
      <el-col :span="24" align="center">
        <el-button size="small" style="width: 88px" @click="showPayStep = false"
          >关 闭</el-button
        >
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      currentPayType: "",
      tableData: [],
      set_pay_id: 0, //所选支付设置id
      name: "",
      key: +new Date(),
      is_diy: {},
      payData: {},
      payType: null,
      showPayStep: false,
    };
  },
  watch: {
    is_diy: {
      handler() {
        this.handlerData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    show(data) {
      this.payType = data.type;
      if (this.payType == "new") {
        this.is_diy = data.val;
      } else {
        this.payData = data.val;
      }
      this.showPayStep = true;
    },
    handlerData() {
      let is_diy = JSON.parse(JSON.stringify(this.is_diy));
      console.log(is_diy);
      this.tableData = [];
      this.key = +new Date(); //强制diff更新
      const typeMap = {
        stage: this.handlerStageHtml,
        roll: this.handlerRollHtml,
      };
      const stageArr = is_diy.stage;
      if (!stageArr || !Array.isArray(stageArr)) return;

      this.tableData = is_diy.stage.map((item) => {
        this.$set(item, "groupHtml", []);
        item.rate = +item.rate;
        item.group.forEach((val) => {
          item.groupHtml.push(typeMap[is_diy.type](val.condition));
        });
        return item;
      });
    },
    handlerStageHtml(list) {
      let checked = list.map(
        (item) => `${item.code_name}${item.set_value}${item.unit || ""}`
      );
      return checked.join(` <span class="content_qie">且</span> `);
    },
    handlerRollHtml(list) {
      let checked = list.map((item) => item.code_name);
      return checked.join(` <span class="content_qie">且</span> `);
    },
  },
  computed: {
    cycleShow() {
      return (cycle) => {
        const cycleMap = {
          30: "月付",
          90: "季付",
          365: "年付",
        };
        return cycleMap[cycle] || "--";
      };
    },
  },
};
</script>

<style>
.el-popup-parent--hidden {
  padding-right: 0 !important;
}
</style>


<style lang="scss" scoped>
.payMethodCard {
  ::v-deep .content_qie {
    color: #409eff;
    font-weight: bold;
  }

  .content_huo {
    color: #ff9502;
    font-weight: bold;
  }

  .total {
    color: #27b148;
  }
}
</style>
