<template>
	<!-- 新增规则弹窗 -->
	<div id="classification">
		<div class="content">
			<div class="title">
				<span>{{ classMsg.header }}</span>
				<div class="close" @click="$emit('close', false)">
          <i class="el-icon el-icon-close"></i>
        </div>
			</div>
			<div class="body">
				<div style="padding: 0 30px;">
					<div class="flex justify-between" style="margin-bottom: 20px;">
						<div></div>
						<el-button size="mini" class="empha" @click="addFromList" style="border: 1px solid #4387f8;">新增</el-button>
					</div>
					<el-table :data="tableClass" :header-cell-style="headerStyle" :row-style="rowStyle" style="width: 100%">
						<el-table-column align="center" label="" type="index" width="60">
							<template slot-scope="{ row }">
								<el-radio @change="radioItem(row)" class="label-hide" v-model="radioTable" :label="row.id"></el-radio>
							</template>
						</el-table-column>
						<el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
						<el-table-column prop="classify_name" :label="classMsg.tabHeader" align="center" width="306">
							<template slot-scope="{ row }">
								<div>
									<el-input v-model="row.classify_name" placeholder="请输入" v-if="row.showInput"></el-input>
									<div v-else class="pos-box">
										<div class="tag-box" style="width: 36px;">
											<el-tag type="danger" size="mini" v-if="row.is_default">默认</el-tag>
										</div>
										<div class="" style="margin-left: 30px;">
											{{ row.classify_name }}
										</div>
										<div class="" style="width: 36px;margin-right: 8px;margin-left: 30px;">
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="address" label="操作" width="280">
							<template slot-scope="{ row, $index }">
								<div class="flex empha justify-content" >
									<template v-if="row.is_uncategory">
										<span v-if="!row.is_default" @click="setDefault(row)">设为默认</span>
									</template>
									<template v-else>
										<template v-if="row.is_add">
											<span @click="addItem(row)" >确定</span>
											<span style="margin-left: 8px;" @click="delAddList($index)">取消</span>
										</template>
										<template v-else>
											<span v-if="!row.showInput" @click="row.showInput = true">编辑</span>
											<span @click="addItem(row)" v-else >确定</span>
											<span v-if="!row.is_default" @click="setDefault(row)" style="margin-left: 8px;">设为默认</span>
											<span v-if="row.is_uncategory == 0" style="margin-left: 8px;" @click="delList(row, $index)">删除</span>
										</template>
									</template>
								</div>
				
							</template>
						</el-table-column>
					</el-table>
					<span style="font-size: 12px; color: #f86464;">{{textMsg}}</span>
				</div>
			</div>
			<div class="btn">
				<span @click="$emit('close', false)">取消</span>
				<span @click="modelClose">确定</span>
			</div>
		</div>
	</div>
</template>

<script>
	/*
		@fromList 点击事件

		@close 关闭事件


		classMsg: {
			header: '询价分类',
			tabHeader: '分类名称',
			moduleType: 'ask',
			alterMsg: '分类名称不能为空',
			getList: '/api/jc/category', // 列表
			addList: '/api/jc/category', // 新增
			copyreaderList: '/api/jc/category', // 编辑
			deltList: '/api/jc/category', // 删除
			alterList: '/api/jc/category/changeDefault/', // 设置默认值
		},



	 */
export default {
	name: 'classification',
	props: {
		// classMsg: {
		// 	type: Object
		// },
	},
	data() {
		return {
      classMsg: {
        header: '选择报价分类',
        tabHeader: '分类名称',
        moduleType: 'app',
        radioId: 0, //数据回显 id，
        alterMsg: '分类名称不能为空',
        getList: '/jc/category', // 列表
        addList: '/jc/category', // 新增
        copyreaderList: '/jc/category', // 编辑
        deltList: '/jc/category', // 删除
        alterList: '/jc/category/changeDefault/', // 设置默认值
      },
			rowStyle: {
				//表格行样式
				color: '#333',
				fontSize: '12px',
				height: '48px'
			},
			headerStyle: {
				//表格头部样式
				background: '#F5F5F5',
				color: '#333',
				fontSize: '12px',
				height: '48px'
			},
			tableClass: [],
			radioTable: 0,
			fromList: {
        id: 0,
      },
			textMsg: '',
		};
	},
	created() {
		this.getGropingList();

	},
	methods: {
		modelClose() {
			this.fromList.id = this.radioTable;
			this.$emit('fromList', this.fromList)
		},

		addFromList() {
			let obj = {
				classify_name: '',
				data_remark: '',
				showInput: true,
				is_add:true,
			};
			this.tableClass.push(obj);
		},
		delAddList(index){
			this.tableClass.splice(index,1)
		},
		delList(row, index) {
			this.$axios.delt(`${this.classMsg.deltList}/${row.id}`,{
        module_type: 'app'
      })
				.then(res => {
					if (res.code == 200) {
						this.$message.success('删除成功');
						this.getGropingList();
					}
				})
				.catch(err => {
					console.log('DELT',err);
					this.$message.error(err.msg);
				});

		},

		radioItem(row) {
			this.fromList = row;
		},

		addItem(row) {
			if (row.classify_name.trim().length == 0) return  this.$message.error(this.classMsg.alterMsg||"分类名称不能为空");
			if (row.id) {
				this.$axios
					.put(`${this.classMsg.copyreaderList}/${row.id}`, {
						classify_name: row.classify_name,
						module_type: this.classMsg.moduleType,
					})
					.then(res => {
						if (res.code == 200) {
							row.showInput = false;
						}
					})
					.catch(err => {
						this.$message.error(err.msg);
					});
			} else {
				this.$axios
					.post(`${this.classMsg.addList}`, {
						classify_name: row.classify_name,
						module_type: this.classMsg.moduleType,
					})
					.then(res => {
						if (res.code == 200) {
							row.showInput = false;
							this.getGropingList();
						}
					})
					.catch(err => {
						this.$Message.error(err.msg);
					});
			}
		},

		setDefault(row) {
			this.$axios.put(`${this.classMsg.alterList}${row.id}`, {
					is_default: '1',
					module_type: this.classMsg.moduleType,
				})
				.then(res => {
          console.log('succ',res.data);
					if (res.code == 200) {
						this.$message.success('设置成功');
						// this.row.is_default
            this.radioTable = row.id;
						this.tableClass.map( i => {
							if (i.id == row.id) {
								i.is_default = 1;
							} else {
								i.is_default = 0;
							}
						})
					}
				})
				.catch(err => {
          console.log(err);
					err.msg &&this.$Message.error(err.msg);
				});
		},

		// 获取列表
		getGropingList() {
			this.$axios.get(this.classMsg.getList, {
				module_type: this.classMsg.moduleType,
			})
				.then(res => {
					if (res.code == 200) {

						res.data.map(item => {
							item.showInput = false;
              if (item.is_default) {
                this.radioTable = item.id;
              }
						});
						this.tableClass = res.data;
						if (this.classMsg.radioId) {
							this.tableClass.map( i => {
								if (i.id == this.classMsg.radioId) {
									this.radioTable = this.classMsg.radioId;
								}
							})
						}
					}
				})
				.catch(err => {
					this.$message.error(err.msg);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
#classification {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
  .empha{
    color:#4387f8;
    cursor: pointer;
  }
  .pos-box{
    position: relative;
    .tag-box{
      position: absolute;
      top:50%;
      left:10px;
      transform: translateY(-50%);
    }
  }
	> .content {
		width: 806px;
		height: 509px;
		background-color: #ffffff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		> .title {
			// width: 100%;
			height: 52px;
			border-bottom: 1px solid #cccccc;
			display: flex;
			align-items: center;
			padding: 0 16px;
			position: relative;
			font-weight: 700;
			> span {
				line-height: 20px;
				font-size: 16px;
				letter-spacing: 1px;
				color: #333333;
			}
			> .close {
				width: 52px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
				> .ivu-icon-md-close {
					color: #999999;
					font-size: 20px;
				}
			}
			> .close:hover {
				background-color: #f74848;
				> .ivu-icon-md-close {
					color: #ffffff;
				}
			}
		}
		> .body {
			flex: 1;
			overflow: auto;
			padding: 30px 20px 0 20px;
			> .inputs {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				> .input_box {
					display: flex;
					align-items: center;
					margin-bottom: 32px;
					position: relative;
					> label {
						width: 94px;
						line-height: 20px;
					}
					&.form-remark {
						margin-top: 20px;
						align-items: unset;
					}
					.error-tips {
						color: #f26161;
						font-size: 12px;
						flex: 0;
						position: absolute;
						bottom: -24px;
						left: 96px;
					}
				}
			}

			> .table {
				.ivu-icon {
					color: #4387f8;
					font-size: 18px;
					margin-right: 10px;
					cursor: pointer;
				}
				.ivu-icon-md-trash {
					margin: 0;
				}
				.action-box {
					span {
						color: #4387f8;
						cursor: pointer;
					}
					i {
						margin: 0 4px;
					}
				}
			}
			> .input_box {
				display: flex;
				align-items: center;
				margin-top: 20px;

				> label {
					margin-right: 20px;
				}
			}
		}
		> .body::-webkit-scrollbar {
			display: none;
		}
		> .btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0 30px;
			> span {
				width: 88px;
				line-height: 32px;
				border-radius: 3px;
				text-align: center;
				cursor: pointer;
				color: #999999;
				border: 1px solid #dce0e7;
				margin-right: 20px;
			}
			> span:last-child {
				color: #ffffff;
				background-color: #4387f8;
			}
		}
	}
}
</style>
<style type="text/css">
	.label-hide .el-radio__label{
		display: none;
	}
</style>
