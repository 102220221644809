<!-- 询价模板 -->
<template>
  <div class="mains">
    <div class="templates">
      <div :class="['status', statusText ? '' : 'daytimes']">
        <div class="status_one">
          <p>{{ statusText ? "发布中" : "已结束" }}</p>
        </div>
      </div>
      <!-- 这里写页面 -->
      <p style="text-align: center">
        <strong
          ><span style="font-size: 18px; width: 80%; display: inline-block"
            >{{ messages.data.title }}询价公告</span
          ></strong
        >
      </p>
      <p style="text-align: center">
        <span style="font-size: 16px"
          >（询价编号：{{ messages.data.serial_number }}）</span
        >
      </p>
      <p>
        <strong><span style="font-size: 14px">一、项目概况</span></strong>
      </p>
      <p class="fonts">
        {{ messages.data.department_name }}就{{
          messages.data.title
        }}
        （询价编号：{{ messages.data.serial_number }}）进行公开询价
        ，诚邀合格应答人参加本次项目报价：
      </p>
      <p>
        <strong
          ><span style="font-size: 14px"
            >二、项目基本情况与询价范围</span
          ></strong
        >
      </p>
      <p class="fonts">1、项目基本情况</p>
      <p class="fonts tender2" v-html="messages.data.ask_ins"></p>
      <p class="fonts">2、询价范围</p>
      <p class="fonts ask-table">
        <el-table
          :data="messages.data.demand"
          :header-cell-style="headerStyle"
          :row-style="rowStyle"
        >
          <el-table-column width="60px" align="center" type="index">
            <template slot="header">
              <div class="font-normal">序号</div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="font-normal">物品名称</div>
              <div class="font-normal">物品编号-小号</div>
              <div class="font-normal">物品描述</div>
            </template>
            <template slot-scope="{ row }">
              <div class="text_address" :title="row.items_name">
                {{ row.items_name }}
              </div>
              <div
                class="text_address"
                :title="row.items_serial_number + '-' + row.ebom_version"
              >
                {{ row.items_serial_number }} - {{ row.ebom_version }}
              </div>
              <div class="text_address" :title="row.items_desc">
                {{ row.items_desc || "--" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot="header">
              <div class="font-normal">需求数量</div>
              <div class="font-normal">需求单位</div>
            </template>
            <template slot-scope="{ row }">
              <div class="text_address">{{ row.num }}</div>
              <div class="text_address">{{ row.unit }}</div>
            </template>
          </el-table-column>

          <el-table-column align="center">
            <template slot="header">
              <div class="font-normal">计价数量</div>
              <div class="font-normal">计价单位</div>
            </template>
            <template slot-scope="{ row }">
              <div class="">
                <div>{{ row.valuation_num || "0" }}</div>
                <div>{{ row.valuation_unit }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot="header">
              <div class="font-normal">交货时间</div>
              <div class="font-normal">交货周期</div>
            </template>
            <template slot-scope="{ row }">
              <div>
                {{ type == "inquiry" ? row.ask_timeof : row.time_of_delivery }}
              </div>
              <div>{{ row.num_of_delivery }}（天）</div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="font-normal">需求部门</div>
              <div class="font-normal">交货地址</div>
            </template>
            <template slot-scope="{ row }">
              <div class="text_address">
                {{ row.section_name || "--" }}
              </div>
              <div class="" v-if="row.adr_of_delivery">
                <div
                  class="text_address"
                  :title="
                    row.adr_of_delivery.province_value +
                    row.adr_of_delivery.city_value +
                    row.adr_of_delivery.area_value +
                    row.adr_of_delivery.address
                  "
                >
                  {{
                    (row.adr_of_delivery.province_value &&
                      row.adr_of_delivery.province_value +
                        row.adr_of_delivery.city_value +
                        row.adr_of_delivery.area_value +
                        row.adr_of_delivery.address) ||
                    "--"
                  }}
                </div>
              </div>
              <div class="" v-else>--</div>
            </template>
          </el-table-column>

          <el-table-column width="120px">
            <template slot="header">
              <div class="font-normal">需求备注</div>
              <div class="font-normal">附件</div>
            </template>
            <template slot-scope="{ row }">
              <div class="table-cell">
                <div class="text_address auto" :title="row.note">
                  {{ row.note || "--" }}
                </div>
                <div
                  class="text_address auto empha underline"
                  :title="
                    row.demand_attachment.name ||
                    row.demand_attachment.full_name
                  "
                  @click="downlad(row.demand_attachment)"
                  v-if="row.demand_attachment"
                >
                  {{
                    row.demand_attachment.name ||
                    row.demand_attachment.full_name
                  }}
                </div>
                <div v-else>/</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </p>
      <p>
        <strong><span style="font-size: 14px">三、询价条件</span></strong>
      </p>
      <p class="fonts">
        <!-- 付款方式：{{ messages.data.pay_method.pay_name }}({{
          messages.data.pay_method.pay_desc
        }}) -->
        <span>付款方式：</span>
        <!-- v-if="hasStep(messages.data.pay_method)" -->
        <span
          @click="showPaySteps(messages.data.pay_method)"
          style="color: #2d8cf0; cursor: pointer"
        >
          {{ messages.data.pay_method.pay_name || "/" }}
        </span>
        <!-- <span v-else>{{ messages.data.pay_method.pay_name || "/" }}</span> -->
      </p>
      <viewPayWay ref="viewPayWay" />
      <p class="fonts">
        发票方式：
        <template v-if="messages.data.invoice_method == 0"
          >增值税专用发票</template
        >
        <template v-if="messages.data.invoice_method == 1"> 普通发票 </template>
      </p>
      <p class="fonts">
        报价税率：{{
          messages.data.rate == -1 ? "不含税" : messages.data.rate + "%"
        }}
      </p>
      <p class="fonts">报价有效期：{{ messages.data.validity_of_quotation }}</p>
      <p class="fonts">条件说明：{{ messages.data.conditions_desc }}</p>
      <p>
        <strong><span style="font-size: 14px">四、询价文件</span></strong>
      </p>
      <p class="fonts">
        1、凡有意参加投标者，请于{{
          messages.data.inquiry_issued_start_time
        }}（北京时间）， 登录{{ messages.data.platName }}（<a
          target="_blank"
          class="empha"
          :href="'http://' + messages.data.platUrl + '/messageList'"
          >{{ "http://" + messages.data.platUrl + "/messageList" }} </a
        >）查看公告报名或登录{{ messages.data.platName }}企业工作台应邀报名。
      </p>
      <p>
        <strong><span style="font-size: 14px">五、报价文件递交</span></strong>
      </p>
      <p class="fonts">
        报价文件递交的截止时间为{{
          messages.data.validity_end_time
        }}（北京时间），合格投标人应在截止时间前通过
        {{
          messages.data.platName
        }}企业工作台进行报价，本项目不接受线下递交报价文件。
      </p>
      <p>
        <strong><span style="font-size: 14px">六、发布公告媒介</span></strong>
      </p>
      <p class="fonts">
        本次询价公告在{{ messages.data.platName }} （<a
          class="empha"
          :href="'http://' + messages.data.platUrl + '/messageList?type=7'"
          target="_blank"
          >{{ "http://" + messages.data.platUrl + "/messageList" }}</a
        >）和{{ messages.data.platName }}企业工作台中发布。
      </p>
      <p>
        <strong><span style="font-size: 14px">七、联系方式</span></strong>
      </p>
      <el-row :gutter="10">
        <el-col
          :span="12"
          v-for="(item, index) in messages.data.contact"
          :key="index"
        >
          <p class="fonts">询价人：{{ item.company_name }}</p>
          <p class="fonts">地址：{{ item.address }}</p>
          <p class="fonts">联系人：{{ item.username }}</p>
          <p class="fonts">联系方式： {{ item.phone }}</p>
        </el-col>
      </el-row>
      <p>
        <strong><span style="font-size: 14px">八、询价附件</span></strong>
      </p>
      <p class="fonts fonts_box">
        <el-row :gutter="10" v-if="messages.data.attachment.length > 0">
          <el-col
            class="bgc_"
            :span="11"
            v-for="(item, index) in messages.data.attachment"
            :key="index"
          >
            <div class="flex" style="width: 100%">
              <div class="">
                <img
                  :src="
                    require(`@/assets/img/files/` +
                      choseFilesIcon(item.image_url) +
                      '.png')
                  "
                  alt=""
                />
              </div>
              <div id="">
                <div class="flex">
                  <div
                    class="fonts text_address"
                    style="width: 200px"
                    :title="item.full_name"
                  >
                    {{ item.full_name }}
                  </div>
                  <span class="fonts uploadFilex" @click="downlad(item)"
                    >下载</span
                  >
                </div>
                <p class="fonts">{{ (item.size / 1024).toFixed(2) }} MB</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </p>
    </div>
    <div class="rights">
      <p @click="lookMore">{{ messages.data.department_name }}</p>
      <p>发布时间：{{ messages.data.updated_at }}</p>
      <div>
        <el-button
          style="width: 100%"
          :disabled="!daytimes"
          @click="joinBussness"
          type="danger"
          >加入商机</el-button
        >
      </div>
      <div style="margin-top: 28px">
        <el-timeline :reverse="false">
          <el-timeline-item
            v-for="(item, index) in activities"
            :key="index"
            color="#0088fe"
            :timestamp="item.titles"
            placement="top"
          >
            <p class="times">{{ item.start }}</p>
            <p class="times">{{ item.end }}</p>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <classList
      v-if="showAlter"
      @close="showAlter = false"
      @fromList="setFromList"
    ></classList>
  </div>
</template>

<script>
import { get, post } from "@/server/ajax.js";
import { choseFilesIcon } from "./choesImgs.js";
import classList from "@/components/InquiryAnnouncement/class.vue";
import viewPayWay from "./viewPayWay.vue";
export default {
  props: ["messages"],
  components: {
    classList,
    viewPayWay,
  },
  data() {
    return {
      choseFilesIcon,
      allData: {},
      showList: {},
      notice_contact: [
        {
          contact_name: "",
        },
        {
          contact_name: "",
        },
      ],
      domain: {
        company_domain: "",
        platform_domain: "",
      },
      location: window.location,
      showAlter: false,
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "48px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "48px",
      },
      notice_block_set: [],
      activities: [],
      daytimes: true,
      statusText: true,
      type: "",
    };
  },
  created() {
    this.daytimes = this.messages.data.inquiry_status == 1 ? false : true;
    this.type = this.$route.query.types;
    if (this.messages.data.is_receive == 0) {
      this.statusText = true;
    } else {
      this.statusText = false;
    }
    let datas = JSON.parse(JSON.stringify(this.messages)); //这里是传过来的数据
    this.allData = datas.data;
    this.showList = this.allData;
    let activities = [];
    activities[0] = {
      titles: "公告时间",
      start: "开始：" + this.messages.data.inquiry_issued_start_time,
      end: "结束：" + this.messages.data.inquiry_issued_end_time,
    };
    activities[1] = {
      titles: "报价时间",
      start: "开始：" + this.messages.data.validity_start_time,
      end: "结束：" + this.messages.data.validity_end_time,
    };
    this.activities = activities;
  },
  methods: {
    hasStep(way) {
      return (
        way?.is_diy &&
        way?.is_diy?.type != "sys" &&
        typeof way?.is_diy == "object"
      );
    },
    showPaySteps(way) {
      // this.$refs.viewPayWay.show(way.is_diy || {});
      if (this.hasStep(way)) {
        // this.is_diy = way.is_diy || {};
        this.$refs.viewPayWay.show({ val: way.is_diy || {}, type: "new" });
        console.log(1);
      } else {
        this.$refs.viewPayWay.show({ val: way || {}, type: "old" });
        console.log(2);
      }
    },
    joinBussness() {
      this.showAlter = true;
      // let params = {
      //   serial_number: new Date().getTime(),   // 这里要对接不同的公司编号
      //   category_id: this.messages.data.category_id,
      //   from_type: this.messages.data.type,
      //   type: this.messages.data.type,
      // };
      // post( "/api/jc/application/addApp/" + this.messages.data.id,  params ).then((res) => {
      //   if (res.code == 200) {
      //     this.$message({
      //       message: "加入成功",
      //       type: "success",
      //     });
      //   }
      // });
    },

    downlad(item) {
      // this.$utils.downFiles(item)
      window.open(item.image_url || item.abs_url || item.attachment_url);
    },
    lookMore() {
      console.log(this.messages);
      let company_id = this.messages.data.company_id;
      this.$router.push({
        path: "/messageList",
        query: {
          company_id: company_id,
          type: this.$route.query.type,
        },
      });
    },

    async setFromList(data) {
      if (data.id) {
        let goodsCode = null;
        this.showAlter = false;
        let obj = {
          app_modular: "quotation", //应用模块 编码
          app_id: 2, //对应的 app_id
          range_id: data.is_uncategory == 1 ? 0 : data.id,
        };
        await get("/rule/get_rule_number", obj).then((res) => {
          if (res.data.num) {
            goodsCode = res.data.num[0];
          }
        });

        let params = {
          serial_number: goodsCode, // 这里要对接不同的公司编号
          category_id: data.id,
          // from_type: this.messages.data.type,
          from_type: "platform", // 来源方式，platform=平台公开,shortlisted=入围公开 前期暂时传递平台公开的值，入围公开未开发。
          type: this.messages.data.type,
        };
        post("/jc/application/addApp/" + this.messages.data.id, params).then(
          (res) => {
            if (res.code == 200) {
              this.daytimes = false;
              this.$message({
                message: "加入成功",
                type: "success",
              });
            }
          }
        );
      } else {
        this.$message.error("没有选择分类");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.templates {
  padding: 40px;
  line-height: 32px;
  box-sizing: border-box;
  position: relative;

  .status {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 3px solid #4387f8;

    .status_one {
      top: 4px;
      left: 4px;
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #4387f8;

      p {
        width: 58px;
        border-radius: 5px;
        top: 10px;
        left: -3px;
        text-align: center;
        position: absolute;
        background-color: #fff;
        text-align: center;
        transform: rotate(-25deg);
        font-weight: 800;
        color: #4387f8;
      }
    }
  }

  .daytimes {
    border-color: #aaa;

    .status_one {
      border-color: #aaa;

      p {
        color: #aaa;
      }
    }
  }
}
.underline {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
}
.font-normal {
  font-weight: normal;
}
.mains {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;

  & > div:nth-child(1) {
    width: 880px;
    background-color: #fff;
  }

  & > div:nth-child(2) {
    width: 300px;
    margin-left: 20px;
    background-color: #fff;
    height: auto;
    box-sizing: border-box;
  }
}

.empha {
  cursor: pointer;
  color: #2d8cf0;
}

.colors {
  color: rgb(0, 176, 240);
}

.rights {
  padding: 20px;
  box-sizing: border-box;

  & > p:nth-child(1) {
    color: #333;
    font-size: 14px;
    text-align: center;
    font-size: 14px;
    font-weight: 800;
    margin: 8px 0 17px 0;
    // line-height: 16px;
    cursor: pointer;
    &:hover {
      color: #0088fe;
      font-weight: bolder;
    }
  }

  & > p:nth-child(2) {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 17px;
  }

  & > div:nth-child(3) {
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center;
    background-color: #f74848;
    border-radius: 3px;
    font-size: 14px;
    line-height: 40px;
  }
}

.timesAll {
  margin-bottom: 18px;
  display: flex;

  img {
    margin-right: 10px;
  }
}

.times {
  font-size: 12px;
  line-height: 28px;
  color: #999;
}

/deep/ .el-timeline-item__timestamp,
.is-top {
  font-weight: 800;
  font-size: 14px;
  color: #0088fe;
}

.uploadFilex {
  margin-left: 8px;
  cursor: pointer;
  color: #2d8cf0;
}

.bgc_ {
  width: 46%;
  margin-right: 12px;
  margin-bottom: 12px;
  background-color: #fafafa;
}

.text_address {
  width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.auto {
    width: auto;
  }
}

.fonts_box {
  margin-left: 25px;
}

.flex {
  display: flex;
}

// .table-cell {
//   padding-right:4px;
// }

.fonts {
  text-indent: 2em;
  font-size: 14px;

  &.tender2 {
    text-indent: 4em;
  }

  &.ask-table {
    padding-left: 32px;
  }
}
</style>

<style lang="scss">
.tender2 {
  * {
    white-space: unset !important;
  }
}
</style>
